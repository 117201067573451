import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"



//On importe les composants
import Header from '../components/header'
import Adresse from '../components/adresse'
import Footer from '../components/footer'
import BandeauContact from '../components/bandeau-contact'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'


export default function RetraitPermis() {


    const Sommaire = [
        { titre: "La rétention immédiate du permis de conduire", ancre: "retention", lien: "#retention" },
        { titre: "La suspension du permis de conduire", ancre: "suspension", lien: "#suspension" },
        { titre: "L'annulation du permis de conduire", ancre: "annulation", lien: "#annulation" },
        { titre: "L'annulation judiciaire", ancre: "judiciaire", lien: "#judiciaire" },
        { titre: "L'annulation administrative", ancre: "administrative", lien: "#administrative" },
        { titre: "Comment éviter l'annulation de son permis de conduire ?", ancre: "eviter", lien: "#eviter" },
        { titre: "Puis-je refuser de restituer mon permis de conduire invalidé ?", ancre: "restituer", lien: "#restituer" }
    ]


    return (
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Retrait ou Annulation de permis : Retrouvez-le rapidement !</title>
                <meta name="description" content="Perdre son permis de conduire est une hantise devenue une réalité de plus en plus fréquente eu égard à la multiplication des moyens de verbalisations sur toutes les routes de France." />
                <html lang="fr" />
                <link rel="icon" href={Favicon} />


            </Helmet>

            <Header />

            <div className="BackGrey">
                <div className="BandeauMain">

                    <div className="BandeauPageContainer">

                        <h1>Retrait ou Annulation de permis de conduire</h1>
                        <h2 align="center">Vous n'avez plus de permis de conduire ?</h2>

                    </div>

                </div>

            </div>


            <div className="AccrochePageMain">

                <div className="AccrochePageContainer">

                    <div className="AccrochePageItem">

                        <StaticImage src="../images/portrait-debrabant.jpg" alt="thierry debrabant avocat" placeholder="blurred" layout="fixed" width={200} height={200} />

                    </div>

                    <div className="AccrochePageItem">

                        <h4 align="center">Maître Thierry DEBRABANT, Avocat</h4>

                        <p align="justify">
                            “Perdre son permis de conduire est une hantise devenue une réalité de plus en plus fréquente eu égard à la multiplication des moyens de verbalisations sur toutes les routes de France.”
                        </p>

                        <div className="AccrochePageItemLink">
                            <Link to="#contact">Contacter</Link>
                        </div>

                    </div>


                </div>
            </div>


            <div className="SommairePage">
                <h2>SOMMAIRE</h2>
                <ul>
                    {
                        Sommaire.map((item, i) => {
                            return (
                                <li key={i}><a href={item.lien}>{item.titre}</a></li>
                            )
                        })
                    }

                </ul>
            </div>

            <div className="TableauMain">

                <h3 align="center" id={Sommaire[0].ancre}>{Sommaire[0].titre}</h3>
                <div className="Contenu">
                    <p align="justify">C’est une mesure de sureté qui est décidée <b>immédiatement après la constatation d’une potentielle infraction</b> par un agent ou un officier de Police judiciaire.</p>

                    <p align="justify">La rétention de permis est <b>valable 72 h ou 120 h</b> en matière de conduite après usage de produits stupéfiants.</p>

                    <p align="justify">A la fin de cette période de 72 h ou 120 h, si vous n’avez pas été rendu destinataire d’une mesure de suspension administrative par la préfecture (voir infra), <b>alors vous pouvez conduire</b>.</p>

                    <p align="justify">Il est toutefois <b>préférable de se renseigner</b> auprès du service verbalisateur qui doit vous indiquer verbalement si votre permis a fait l’objet d’une mesure de suspension administrative par la préfecture.</p>

                    <p align="justify"><b>Si le Préfet n’a pas prononcé d’arrêté de suspension du permis de conduire</b>, alors vous pouvez récupérer votre permis de conduire soit en le récupérant directement auprès du service verbalisateur, soit par voie postale par courrier recommandé avec accusé de réception.</p>

                </div>

                <h3 align="center" id={Sommaire[1].ancre}>{Sommaire[1].titre}</h3>
                <div className="Contenu">
                    <p align="justify">C’est cette mesure qui vient généralement <b>immédiatement après la période de rétention</b> évoquée juste au dessus.</p>

                    <p align="justify">On parle de suspension administrative, <b>c’est à dire décidée par une autorité administrative</b> (Préfecture ou Sous-Préfecture) par opposition à la suspension judiciaire qui est elle prononcée par une juridiction (Tribunal de Police, Tribunal Correctionnel, Cour d’Appel).</p>

                    <p align="justify">Il faut retenir que le Préfet peut mettre en œuvre une telle mesure qui est alors qualifiée de <b>sanction administrative</b> dès que vous êtes soupçonné (alors que vous êtes en même temps présumé innocent !) d’avoir commis une infraction au Code de la Route punissable par une peine accessoire de suspension judiciaire du permis de conduire.</p>

                    <p align="justify">En pratique, la suspension administrative du permis de conduire <b>intervient le plus souvent après une verbalisation</b> pour <u>les infractions suivantes :</u></p>

                    <ul>
                        <li><i>Conduite en état alcoolique</i> constatée par un éthylomètre ou une analyse sanguine,</li>
                        <li><i>refus de se soumettre</i> aux vérifications concernant l’alcoolémie</li>
                        <li><i>conduite après usage de produits stupéfiants</i> (l’appellation « conduite sous l’emprise de stupéfiants est incorrecte, nous en parlerons dans un article dédié),</li>
                        <li><i>excès de vitesse</i> de plus de 40 km,</li>
                        <li><i>homicide involontaire</i>,</li>
                        <li><i>usage d’un téléphone au volant cumulé avec une autre infraction</i> comme un excès de vitesse ou non respect de distances de sécurité,</li>
                    </ul>

                    <p align="justify" id="important"><b>Il existe une technique tout à fait légale pour pouvoir continuer à conduire</b> même en cas de suspension administrative du permis de conduire, il faut pour cela <a href="#contact">me consulter</a> afin que je puisse vérifier si cette possibilité vous est offerte dans votre situation précise.</p>

                    <p align="justify">Il est toujours possible de <b>contester une mesure de suspension administrative</b> du permis de conduire décidée par la Préfecture, le délai pour le faire est de <b>deux mois</b>, comme pour une annulation de permis.</p>

                    <p align="justify">Dans de nombreux cas, <b>il est possible de supprimer, raccourcir fortement, ou transformer</b> la mesure de suspension en restriction du droit de conduire sans éthylotest anti-démarrage (EAD).</p>

                    <p align="justify">N’hésiter pas à <a href="#contact">me contacter</a> pour connaître toutes les possibilités qui s’offrent à vous.</p>

                    <p align="justify">Une fois que votre affaire est jugée par le Tribunal, ce dernier a la possibilité de <b>prononcer la peine complémentaire</b> de suspension judiciaire du permis de conduire.</p>

                    <p align="justify">Là encore, <b>une bonne préparation et une étude approfondie de votre dossier</b> permet de diminuer fortement voire de supprimer en cas de relaxe ou d’annulation de procédure la peine de suspension judiciaire du permis de conduire.</p>

                    <p align="justify">Vous trouverez sur ce site dans la catégorie « nos résultats » une sélection de décisions qui ne condamnent le conducteur à <b>aucune suspension du permis de conduire</b> même en cas de commission d’un délit.</p>

                    <BandeauContact />

                    <h3 align="center" id={Sommaire[2].ancre}>{Sommaire[2].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">Contrairement à la rétention et la suspension, un permis de conduire annulé ou invalidé perd définitivement sa validité, il faut alors repasser les épreuves théoriques et pratiques (uniquement l’épreuve théorique du code de la route si votre permis de conduire a plus de 3 ans d’ancienneté).</p>

                        <p align="justify"><u>Il existe deux types d’annulation :</u></p>
                    </div>

                    <h3 align="center" id={Sommaire[3].ancre}>{Sommaire[3].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">L'annulation judiciaire du permis de conduire qui comme son nom l’indique, est <b>la conséquence d’une décision par un Tribunal</b>. A noter qu’en cas de récidive légale de conduite en état alcoolique ou conduite après usage de produits stupéfiants, en cas de condamnation, le tribunal n’a aucun pouvoir d’appréciation et constate l’annulation du permis de conduire.</p>

                        <p align="justify">Dans cette situation, le seul recours possible consiste à <b>contester la décision en interjetant appel</b> afin d’obtenir la relaxe sur le fond ou par l’annulation de la procédure.</p>

                        <p align="justify">Attention le délai pour contester une annulation judiciaire du permis de conduire peut être très bref puisqu’il est généralement de <b>10 jours à compter du prononcé du jugement</b>, ce délai pouvant être porté à 45 jours en cas de procédure ayant recours à l’ordonnance pénale délictuelle.</p>

                        <BandeauContact />

                    </div>

                    <h3 align="center" id={Sommaire[4].ancre}>{Sommaire[4].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">Cette situation est une des plus redoutées par les professionnels de la route, elle se matérialise par <b>la fameuse lettre 48SI</b> par laquelle le Ministère de l’Intérieur informe le titulaire d’un permis de conduire que ce dernier a permis sa validité suite à la perte d’un trop grand nombre de points qui amène le solde de ce dernier à 0 ou moins.</p>

                        <p align="justify">Comme toute décision administrative individuelle défavorable, la lettre 48SI peut être <b>contestée dans un délai de 2 mois maximum</b> à compter de la date de réception effective.</p>

                        <p align="justify">Attention, la lettre 48SI est souvent éditée et datée plusieurs semaines avant d’être expédiée par la poste, quelque fois 5 semaines ! Ce n’est pas la date qui figure au bas à droite de la lettre qui tient lieu de point de départ du délai de 2 mois, mais bien <b>la date de première présentation par la poste</b> au domicile du titulaire du permis de conduire.</p>

                        <p align="justify">En cas de réception d’une lettre 48SI, il ne faut surtout pas hésiter à <a href="#contact">me consulter</a> il sera généralement aisé de récupérer le permis de conduire annulé, car <b>dans environ 25 % des cas l’annulation du permis résulte d’une simple erreur</b> de l’administration dans la comptabilité des points.</p>

                        <br />

                        <p align="justify">En effet, il existe de <u>nombreux délais dont l’expiration engendre la récupération automatique de points :</u></p>

                        <ul>
                            <li>Dans le cas d’une infraction ayant entrainé le retrait d’un point, ce point est <b>restitué à l’issue d’un délai de 6 mois</b> sans infraction </li>
                            <li>Dans le cas d’une infraction ayant entrainé un retrait de plus d’un point, tous les points sont <b>restitués à l’issue d’un délai de 2 ans</b> sans infraction, et <b>3 ans en cas de commission d’une infraction de 4ème ou 5ème classe</b>. </li>
                            <li>En plus de ces délais, <b>tous les points perdus sont restitués au bout de 10 ans</b> si dans cet intervalle aucune reconstitution totale du nombre de points n’est intervenue, autrement dit, si votre permis n’est jamais repassé à 12 points. </li>
                        </ul>

                        <br />

                        <p align="justify">Il s’avère que le Bureau Nationale des Droits à Conduire (ex- Fichier National du Permis de Conduire) est informatisé, mais le super ordinateur <b>se trompe très régulièrement</b>.</p>

                        <p align="justify">Il ne faut surtout pas hésiter en cette hypothèse à <b>contester</b> car je régularise la situation généralement <b>en moins de 15 jours.</b></p>

                        <br />

                        <p align="justify">Il existe <u>3 procédures pour contester une annulation administrative du permis de conduire :</u></p>

                        <ul>
                            <li><b>Le recours gracieux</b>, c’est à dire adressé directement à l’auteur de l’auteur de la décision 48 SI sans passer par un tribunal,</li>
                            <li><b>Le recours contentieux</b>, qui consiste en un recours déposé devant le Tribunal administratif,</li>
                            <li><b>Le recours contentieux doublé d’un recours en référé</b> visant à solliciter la suspension de la décision d’annulation du permis de conduire.</li>
                        </ul>

                        <br />

                        <p align="justify">Avant d’engager une procédure, <b>je procède systématiquement à une analyse approfondie</b> de votre dossier au moyen si possible de votre relevé intégral d’information qui permet <u>dès le premier rendez vous</u> de connaître les chances de succès d’une éventuelle procédure. </p>

                        <p align="justify"><i>A la différence de certains cabinets parisiens</i> que je ne nommerai pas et qui vous garantisse la récupération de votre permis de conduire alors même qu’ils n’ont pas vu la moindre pièce de votre dossier ou qui ne vous ont jamais rencontré (vous ne les rencontrerez pas d’avantage ultérieurement), <b>je n’engage jamais un recours sans avoir informé le client de manière très précise sur les chances de succès</b>. </p>

                        <p align="justify">De la même manière, de nombreux cabinets qui découvrent la matière proposent systématiquement des référés, et ce uniquement pour augmenter artificiellement le montant de leur devis et faire croire au client que la récupération de leur permis de conduire sera plus rapide. </p>

                        <p align="justify">Il faut fuir ces cabinets comme la peste, car <b>ils démontrent par cette approche grossière et non personnalisée qu’ils ne maitrisent absolument pas le contentieux</b>.</p>

                        <p align="justify">Le cabinet de Thierry DEBRABANT justifie de <b>16 années d’expérience</b> en la matière et saura vous conseiller avec discernement et intégrité.</p>

                        <p align="justify">C’est pour cette raison que <b>le taux de récupération de permis de mon cabinet est proche de 100 %</b>, car je n’engage jamais une procédure sans avoir tout vérifié préalablement que les chances réelles de succès du recours. </p>


                        <p align="justify">Vous trouverez une sélection de décisions dans la partie <a href="/">nos résultats</a>. </p>

                        <BandeauContact />

                        <p align="justify"><i>Pour une étude gratuite et sans engagement en m’adressant directement votre lettre 48 SI et <b>si possible votre relevé d’information intégral</b> du permis de conduire.</i></p>



                    </div>

                    <h3 align="center" id={Sommaire[5].ancre}>{Sommaire[5].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">Il est souvent beaucoup plus simple et moins couteux de <b>préserver un permis de conduire</b>, même en cas de multiples verbalisations, que de contester et récupérer un permis annulé.</p>

                        <p align="justify">Plusieurs formules personnalisées sont proposées par mon cabinet qui <b>vous permettent de déléguer totalement la gestion de vos points</b>v et ne plus jamais prendre le risque d’une annulation de permis.</p>
                    </div>

                    <h3 align="center" id={Sommaire[6].ancre}>{Sommaire[6].titre}</h3>
                    <div className="Contenu">
                        <p align="justify">En théorie, un tel refus <b>constitue un délit</b> prévu et réprimé par l’article L 214-17 du Code de la Route d’une peine de 2 ans d’emprisonnement et 4.500 € d’amende.</p>

                        <p align="justify">Il est donc très nettement <b>préférable de contester judiciairement l’annulation du permis de conduire</b> avec l’une des 3 procédures évoquées plus haut, car la récupération de votre permis de conduire a un effet rétroactif.</p>

                        <p align="justify">En effet, en la récupération de votre permis de conduire décidée par un Tribunal Administratif a un effet rétroactif, c’est à dire que l’annulation de votre permis de conduire <b>sera réputée n’avoir jamais existée</b>, il est donc impossible d’avoir commis une infraction en refusant de le restituer.</p>

                        <p align="justify">Le même raisonnement s’applique si vous avez conduit postérieurement à l’annulation de votre permis de conduire : la récupération ultérieure de votre permis de conduire <b>fera disparaître rétroactivement l’infraction</b>.</p>
                    </div>

                    <BandeauContact />

                </div>



            </div>

            <Adresse />

            <Footer />


        </HelmetProvider >
    )
}
